/* eslint-disable no-undef */
import React, { useState } from 'react'

import axios from 'axios'
import { useRouter } from 'next/router'
import toast, { Toaster } from 'react-hot-toast'
import Link from 'next/link'
import Cookies from 'js-cookie'
import { redirectToHomeIfAuthenticated } from '../../utils/auth'
import toastr from 'toastr'
import { decryptData } from '../../utils/cryptoUtils'
import { encryptDataAES } from '../../utils/cryptoAES'
// Redirect if already authenticated
export async function getServerSideProps(context) {
  return redirectToHomeIfAuthenticated(context)
}

const Login = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const router = useRouter()
  const [rememberMe, setRememberMe] = useState(false)

  const handleLogin = async (event) => {
    event.preventDefault() // Prevent form submission reload

    const dataToSend = { email, password }
    const nuicao = encryptDataAES(dataToSend)

    try {
      const response = await axios.post(
        '/api/login',
        { nuicao } // Gửi dữ liệu đã mã hóa
      )

      if (response.status === 200) {
        const { igatesession_avn, igatesession_rvn, user } = response.data
        // console.log('Dữ liệu mã hóa:', user.encryptedData)
        const secretKey = process.env.SECRET_KEY // Khóa bí mật

        const { encryptedData, iv, hmac } = user
        const decryptedPayload = decryptData(encryptedData, iv, hmac, secretKey)
        // console.log('Dữ liệu giải mã:', decryptedUser)

        // Store access and refresh tokens in cookies
        if (rememberMe) {
          Cookies.set('igatesession_avn', igatesession_avn, {
            expires: 1,
            sameSite: 'Lax',
            domain: '.wolfteam.vn',
            secure: process.env.NODE_ENV === 'production',
          })
          Cookies.set('igatesession_rvn', igatesession_rvn, {
            expires: 7,
            sameSite: 'Lax',
            domain: '.wolfteam.vn',
            secure: process.env.NODE_ENV === 'production',
          })
        } else {
          Cookies.set('igatesession_avn', igatesession_avn, {
            expires: 1,
            sameSite: 'Lax',
            domain: '.wolfteam.vn',
            secure: process.env.NODE_ENV === 'production',
          })
        }

        toast.success('Đăng nhập thành công.')
        toastr.success(
          'Đăng nhập thành công!',
          `Xin chào ${decryptedPayload.username}!`
        )

        router.push('/admindashboard/homeindex')
      }
    } catch (error) {
      // console.clear()
      const err = error // Sử dụng kiểu dữ liệu đã định nghĩa
      // console.log('Response:', err.response?.data.error)
      if (err.response) {
        const responseData = err.response?.data.error
        //  setErrorLogin(err.response?.data.error)

        switch (err.response.status) {
          case 400:
            toastr.error(`${responseData}`)
            break
          case 401:
            toastr.error(`${responseData}`)
            break
          case 403:
            toastr.error(`${responseData}`)
            break
          case 404:
            toastr.error(`${responseData}`)
            break
          case 429:
            toastr.error(
              'Bạn đã thực hiện quá nhiều lần đăng nhập thất bại. Vui lòng thử lại sau 15 phút.'
            )
            break
          case 500:
            toastr.error('Đã có lỗi xảy ra, vui lòng thử lại sau.')
            break
          default:
            toastr.error('Đã có lỗi xảy ra, vui lòng thử lại sau.')
            break
        }
      }
      // console.log('decryptedErrr  ', error.response.data.message)
      // if (error.response && error.response.status === 400) {
      //   const message = error.response.data.message
      //   toastr.error(message) // Hiển thị thông báo lỗi từ server
      // } else if (error.response && error.response.status === 401) {
      //   const message = error.response.data.message
      //   toastr.error(message)
      // } else if (error.response && error.response.status === 404) {
      //   const message = error.response.data.message
      //   toastr.error(message)
      // } else {
      //   toastr.error('Đã có lỗi xảy ra, vui lòng thử lại sau.')
      // }
    }
    // finally {
    //   console.log('Yêu cầu hoàn thành')
    // }
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleLogin(e)
    }
  }

  return (
    <>
      <Toaster />
      <div
        className="container-scroller d-flex align-items-center justify-content-center"
        style={{
          backgroundImage: 'url(/VnUV-background.png)',
          width: '100vw',
          height: '100vh',
        }}
      >
        <div className="container-fluid ">
          <div className="d-flex align-items-center justify-content-center auth px-0">
            <div className="col-lg-4">
              <div
                className="auth-form-light text-left py-5 px-4 px-sm-5 border rounded"
                style={{ backgroundColor: 'white' }}
              >
                <div className="brand-logo text-center">
                  <img src="/workspace.png" alt="logo" />
                </div>
                <h4 className="text-center">Xin chào! hãy bắt đầu</h4>
                <h6 className="font-weight-light text-center">
                  Tiếp tục đăng nhập.
                </h6>
                <form className="pt-3" onSubmit={handleLogin}>
                  <div className="form-group">
                    <input
                      type="email"
                      name="email"
                      className="form-control form-control-lg"
                      placeholder="Địa chỉ email"
                      style={{ border: '1px solid #ccc' }}
                      onChange={(e) => setEmail(e.target.value)}
                      onKeyDown={handleKeyDown}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="password"
                      name="password"
                      autoComplete="true"
                      className="form-control form-control-lg"
                      placeholder="Mật khẩu"
                      style={{ border: '1px solid #ccc' }}
                      onChange={(e) => setPassword(e.target.value)}
                      onKeyDown={handleKeyDown}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="checkbox"
                      id="rememberMe"
                      checked={rememberMe}
                      onChange={(e) => setRememberMe(e.target.checked)}
                    />
                    <label htmlFor="rememberMe" className="ml-2">
                      Ghi nhớ đăng nhập
                    </label>
                  </div>
                  <div className="mt-3">
                    <button
                      className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                      type="submit"
                    >
                      Đăng nhập
                    </button>
                  </div>
                  <div className="my-2 d-flex justify-content-between align-items-center">
                    <Link
                      href="/forgotpassword"
                      className="auth-link text-primary"
                    >
                      Quên mật khẩu?
                    </Link>
                  </div>
                  <div className="text-center mt-4 font-weight-light">
                    Chưa có tài khoản?{' '}
                    <Link href="/register" className="text-primary">
                      Tạo mới
                    </Link>
                    <p>WolfTeam Corporation</p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Login
