import './global.css'

import '../public/styles/assets/css/font-awesome.min.css'
import '../public/css/bootstrap.min.css'

import '../public/css/sky.css'

import '../public/vendors/feather/feather.css'
import '../public/vendors/ti-icons/css/themify-icons.css'
//toastr
import 'toastr/build/toastr.min.css'
import type { AppProps } from 'next/app'
import React from 'react'
import { ThemeProvider } from 'next-themes'

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <ThemeProvider attribute="class" defaultTheme="light">
      <Component {...pageProps} />
    </ThemeProvider>
  )
}

export default MyApp
