import CryptoJS from 'crypto-js'

const secretKey = 'cacheEntry.responseCache.get'

/**
 * Mã hóa dữ liệu sử dụng AES
 * @param {Object} data - Dữ liệu cần mã hóa
 * @returns {string} - Chuỗi đã mã hóa dạng Base64
 */
export const encryptDataAES = (data) => {
  const stringifiedData = JSON.stringify(data) // Chuyển Object thành JSON
  const encrypted = CryptoJS.AES.encrypt(stringifiedData, secretKey).toString()
  return encrypted
}

/**
 * Giải mã dữ liệu AES
 * @param {string} encryptedData - Chuỗi dữ liệu đã mã hóa
 * @returns {Object} - Dữ liệu gốc đã giải mã
 */
export const decryptDataAES = (encryptedData) => {
  const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey)
  const decryptedData = bytes.toString(CryptoJS.enc.Utf8)
  return JSON.parse(decryptedData)
}
