import CryptoJS from 'crypto-js'

// Hàm mã hóa dữ liệu
export function encryptData(data, secretKey) {
  const key = CryptoJS.enc.Hex.parse(secretKey) // Khóa 256-bit
  const iv = CryptoJS.lib.WordArray.random(16) // IV 128-bit ngẫu nhiên

  // Mã hóa dữ liệu
  const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  })

  // Tạo HMAC để đảm bảo tính toàn vẹn
  const hmac = CryptoJS.HmacSHA256(encrypted.toString(), key).toString()

  return {
    encryptedData: encrypted.toString(),
    iv: iv.toString(CryptoJS.enc.Hex),
    hmac: hmac,
  }
}

// Hàm giải mã dữ liệu
export function decryptData(encryptedData, iv, hmac, secretKey) {
  try {
    const key = CryptoJS.enc.Hex.parse(secretKey)
    const parsedIV = CryptoJS.enc.Hex.parse(iv)

    // Kiểm tra tính toàn vẹn dữ liệu
    const recalculatedHmac = CryptoJS.HmacSHA256(encryptedData, key).toString()
    if (recalculatedHmac !== hmac) {
      throw new Error('Data integrity check failed')
    }

    // Giải mã dữ liệu
    const bytes = CryptoJS.AES.decrypt(encryptedData, key, {
      iv: parsedIV,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    })

    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
  } catch (error) {
    console.error('Error during decryption:', error)
    return { error: 'Decryption failed' }
  }
}
